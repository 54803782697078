import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import toast from "react-hot-toast";
import {
  useUpdateUserMutation,
  useSessionTokenQuery,
} from "../../features/user/userApiSlice";
import { HeadProps, Link, navigate } from "gatsby";
import { handleErrorResponse } from "../../app/apiSlice";
import { useRequireAuthentication } from "../../features/user/hooks";
import { useCurrentUserQuery } from "../../features/user/userApiSlice";
import FormPageWrapper from "../../components/FormPageWrapper";
import Layout from "../../components/Layout";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonSpinner from "../../components/ButtonSpinner";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../../components/FormErrorMessage";
import Seo from "../../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

interface ChangePasswordFormValues {
  pass: string;
  existing: string;
}

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormValues>();

  useRequireAuthentication();

  // Get the already_logged_in flag. Checks if the user was directed here because of using
  // a password reset link while logged in.
  const location = useLocation();
  const queryParams = parse(location.search);
  const isLoggedInQueryParam = Number(queryParams["already_logged_in"]);
  useEffect(() => {
    if (isLoggedInQueryParam === 1) {
      toast.error(
        "You are already logged in, so you will need your existing password in order to change your password."
      );
    }
  }, [isLoggedInQueryParam]);

  const { data: currentUser } = useCurrentUserQuery();
  const { data: sessionToken } = useSessionTokenQuery(undefined, {
    skip: !currentUser?.is_authenticated,
  });
  const [updateUserTrigger, { isLoading }] = useUpdateUserMutation();

  const onSubmit: SubmitHandler<ChangePasswordFormValues> = (values) => {
    updateUserTrigger({
      sessionToken,
      id: currentUser.data.uuid,
      payload: {
        attributes: {
          pass: {
            existing: values.existing,
            value: values.pass,
          },
        },
      },
    })
      .unwrap()
      .then((success) => {
        toast.success("Your password has successfully been changed.");
        navigate("/user/settings");
      })
      .catch((rejected) => {
        console.error(rejected);
        handleErrorResponse(rejected).forEach((m) => {
          const e = Error(m);
          e.name = "ChangePassword: onSubmit";
          Sentry.captureException(e);
        });
      });
  };

  return (
    <Layout>
      <FormPageWrapper title="Change password">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <label
              htmlFor="existing"
              className="font-bold text-neutral-700 block mb-1"
            >
              Current password
            </label>
            <input
              id="existing"
              {...register("existing", {
                required: true,
                maxLength: 512,
                minLength: 6,
              })}
              placeholder="xxxxxxxx"
              type="password"
              className={`border shadow p-3 w-full rounded text-neutral-700 ${
                errors.existing ? "border-red-300" : "border-neutral-300"
              }`}
            ></input>
            {errors.existing?.type === "required" && (
              <FormErrorMessage>
                The current password is required.
              </FormErrorMessage>
            )}
            {errors.existing?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 512 characters.
              </FormErrorMessage>
            )}
            {errors.existing?.type === "minLength" && (
              <FormErrorMessage>
                Must be at least 6 characters.
              </FormErrorMessage>
            )}
            <label
              htmlFor="pass"
              className="font-bold text-neutral-700 block mb-1 mt-2"
            >
              New password
            </label>
            <input
              id="pass"
              {...register("pass", {
                required: true,
                maxLength: 512,
                minLength: 6,
              })}
              placeholder="xxxxxxxx"
              type="password"
              className={`border shadow p-3 w-full rounded text-neutral-700 ${
                errors.pass ? "border-red-300" : "border-neutral-300"
              }`}
            />
            {errors.pass?.type === "required" && (
              <FormErrorMessage>
                The current password is required.
              </FormErrorMessage>
            )}
            {errors.pass?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 512 characters.
              </FormErrorMessage>
            )}
            {errors.pass?.type === "minLength" && (
              <FormErrorMessage>
                Must be at least 6 characters.
              </FormErrorMessage>
            )}
          </div>
          <ButtonPrimary
            type="submit"
            size="20"
            disabled={!currentUser?.data?.uuid || !sessionToken || isLoading}
          >
            Submit
            <ButtonSpinner enabled={isLoading} />
          </ButtonPrimary>
          <Link
            to="/user/settings"
            className="text-red-700 underline hover:text-red-600 inline-block ml-8"
          >
            Cancel
          </Link>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default ChangePassword;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Change password | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
